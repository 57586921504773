import axios from "axios";

export function retrieveQrCode(token) {
    return axios.get( `${process.env.REACT_APP_TERMINAL_SERVICE_URL}/v1/retrieve-qr-code`, {
        headers: {'Authorization': `Bearer ${token}`}
    });
}

export function statusCheck(registrationCode, token) {
    return axios.get(`${process.env.REACT_APP_TERMINAL_SERVICE_URL}/v1/init-status-check`, {
        headers: {'Authorization': `Bearer ${token}`},
        params: {
            registrationCodeId: registrationCode
        }
    })
}

export function listTerminals(token) {
    return axios.get(`${process.env.REACT_APP_TERMINAL_SERVICE_URL}/v1/list-terminals`, {
        headers: {'Authorization': `Bearer ${token}`}
    })
}

export function deactivateTerminal(token, installationId) {
    return axios.put(`${process.env.REACT_APP_TERMINAL_SERVICE_URL}/v1/${installationId}/deactivate`, {
        headers: {'Authorization': `Bearer ${token}`}
    })
}

export function checkIfMerchantServiceExists(token) {
    return axios.get(`${process.env["REACT_APP_TERMINAL_SERVICE_URL"]}/v1/check-merchant-service`, {
        headers: {'Authorization': `Bearer ${token}`}
    });
}