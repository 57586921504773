import React, {useEffect} from "react";
import {authenticate} from "redux/actions/authActions"
import './App.css'
import * as async from 'async';

import componentStyles from "argon/assets/theme/views/admin/buttons.js";
import componentStylesButtons from "argon/assets/theme/components/button.js";

import {makeStyles} from "@material-ui/core/styles";
// import AgreementCard from "./views/agreements/AgreementCard";
import {connect} from "react-redux";
import Loader from "./views/common/Loader";
import UnAuthorized from "./views/common/UnAuthorized";
import ErrorPage from "./views/common/ErrorPage";
import {
    retrieveQrCode,
    statusCheck,
    listTerminals,
    deactivateTerminal,
    checkIfMerchantServiceExists
} from "./api/terminalServiceApis";
// import {QrDialog} from "./views/QrDialog";
// import {InstallationIdDialog} from "./views/InstallationIdDialog";
// import {TimeOutDialog} from "./views/TimeOutDialog";
// import {VerifyingDialog} from "./views/VerifyingDialog";
// import {Tooltip, IconButton, withStyles} from "@material-ui/core";
import {Tooltip, withStyles} from "@material-ui/core";
// import Icon from '@material-ui/core/Icon';
// import {DeactivateDialog} from "./views/DeactivateDialog";
// import {DeactivatedSuccessDialog} from "./views/DeactivatedSuccessDialog";
// import {Error} from "./views/Error";

const useStyles = makeStyles(componentStyles);
const useStylesButtons = makeStyles(componentStylesButtons);

const TIMEOUT = parseInt(process.env.REACT_APP_QR_EXPIRE_TIMEOUT_IN_SECONDS);

let expiredLocal = false;
let close = false;
let installationId;

export function Home({authState, authenticate}) {
    const classes = {...useStyles(), ...useStylesButtons()};

    const [open, setOpen] = React.useState(false);
    const [qrLoaded, setQrLoaded] = React.useState(false);
    const [qrLoading, setQrLoading] = React.useState(false);
    const [qrCode, setQrCode] = React.useState("");
    const [passCode, setPassCode] = React.useState("");
    const [expired, setExpired] = React.useState(false);
    const [used, setUsed] = React.useState(false);
    const [merchantServiceExists, setMerchantServiceExists] = React.useState(true);

    const childFunc = React.useRef(null)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('s');

    const [terminals, setTerminals] = React.useState([]);
    const [dateSortingOrder, setDateSortingOrder] = React.useState('ASC');
    const [stateSortingOrder, setStateSortingOrder] = React.useState('ASC');

    const [terminalsLoading, setTerminalsLoading] = React.useState(true);
    const [timeOut, setTimeOut] = React.useState(false);
    const [deactivate, setDeactivate] = React.useState(false);
    const [deactivateSuccess, setDeactivateSuccess] = React.useState(false);
    const [deactivateInstallationId, setDeactivateInstallationId] = React.useState(false);
    const [errorOccured, setErrorOccured] = React.useState(false);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        authenticate(sessionId);
    }, [sessionId]);

    useEffect(() => {
        setTerminalsLoading(true);
        if (authState.accessToken) {
            checkIfMerchantServiceExists(authState.accessToken).then(response => {
                setMerchantServiceExists(response.Data.merchantServiceExists);
            });
        }
        if (authState.accessToken || used) {
            setListTerminal(authState.accessToken);
        }
    }, [authState.accessToken, used]);

    const setListTerminal = (token) => {
        listTerminals(token).then(res => {
            setTerminals(res.Data);
            setTerminalsLoading(false);
            }).catch((error) => {
                console.log(error);
                setTerminalsLoading(false);
            });

    };
    const onExpired = () => {
        console.warn("Expired QR!");
        if (!close) {
            setOpen(false);
            console.log(`Updating expired local to ${expiredLocal}`)
        }
    }

    const onDeactivate = (installationId) => {
        setDeactivate(true);
        setDeactivateInstallationId(installationId);
    }

    const onSort = (column, isDate) => {
        let sortedTerminals = terminals.slice();
        if(isDate){
            // eslint-disable-next-line array-callback-return
            sortedTerminals.sort(function (t1, t2) {
                    if ('ASC' === dateSortingOrder) {
                        setDateSortingOrder('DESC')
                        return new Date(t2[column].split('-').reverse().join('-')) - new Date(t1[column].split('-').reverse().join('-'))

                    } else if ('DESC' === dateSortingOrder) {
                        setDateSortingOrder('ASC')
                        return new Date(t1[column].split('-').reverse().join('-')) - new Date(t2[column].split('-').reverse().join('-'))
                    }
                }
            );

        }else{

                    if ('ASC' === stateSortingOrder) {
                        setStateSortingOrder('DESC')
                        sortedTerminals.sort((t1, t2) => t1[column].localeCompare(t2[column]))

                    } else if ('DESC' === stateSortingOrder) {
                        setStateSortingOrder('ASC')
                        sortedTerminals.sort((t1, t2) => t2[column].localeCompare(t1[column]))
                    }
        }

        setTerminals(sortedTerminals)
    }


    if (authState.isAuthorizing) {
        return <Loader></Loader>
    } 
    else if (authState.isServerError) {
        return <ErrorPage></ErrorPage>
    // } else if (!authState.isAuthorizing && !merchantServiceExists) {
    //     return (
    //         <div className="container">
    //             <h1>
    //                 This merchant account has not been registered for Novatti Tap2Pay. If you require this service, please contact your account manager or support@novattipayments.com.
    //             </h1>
    //         </div>
    //     )
    } else if (!authState.isAuthorized) {
        return <UnAuthorized></UnAuthorized>
    }

    const handleClickOpen = () => {
        const token = authState.accessToken;
        setQrLoading(true);
        retrieveQrCode(token).then(response => {
            setQrLoading(false);
            setQrLoaded(true);
            setExpired(false);
            close = false;
            setQrCode(response.Data.QrCode);
            setPassCode(response.Data.Passcode)
            let statusResponse;
            let count = 0;
            async.whilst(
                function test(cb) {
                    console.log(`Expired local: ${expiredLocal}`);
                    cb(
                        null,
                        expiredLocal === false && !close && ((statusResponse && statusResponse['Status'] === "Unused") || count < (TIMEOUT / 5))
                    );
                },
                function iter(callback) {
                    count++;
                    statusCheck(response.Data.RegistrationCode, token).then(res => {
                        statusResponse = res.Data;
                        if (statusResponse && statusResponse['Status'] === "Used") {
                            installationId = statusResponse['InstallationID'];
                            childFunc.current();
                            setUsed(true);
                            setOpen(false);
                        } else {
                            setTimeout(function () {
                                callback(null, count);
                            }, 5000);
                        }
                    }).catch((error) => {
                        console.log(error);
                        if (error.response.status === 400) {
                            expiredLocal = true;
                            setExpired(false);
                            setTimeOut(true);
                        }
                    })
                },
                function (err, n) {
                    console.error(err);
                    // 5 seconds have passed, n = 5
                    if (expired) {
                        expiredLocal = true;
                        setExpired(false);
                        setTimeOut(true);
                    }
                }
            );
            setOpen(true);
            close = false;
        }).catch(err => {
            console.error(err);
            setOpen(false);
            setQrLoading(false);
            setErrorOccured(true);
        })
    };

    const handleClose = () => {
        setOpen(false);
        setExpired(false);
        setUsed(false);
        expiredLocal = false;
        close = true;
        setTimeOut(false);
        setDeactivate(false);
        setDeactivateSuccess(false);
        setErrorOccured(false);
    };

    const handleDeactivate = () => {
        const token = authState.accessToken;
        const id = deactivateInstallationId;
        deactivateTerminal(token, id).then(res => {
            if (res.Data['installationId']===id) {
                setTerminalsLoading(true);
                setListTerminal(token);
                setDeactivateSuccess(true);
            }
            setDeactivate(false);
        }).catch((error) => {
            console.log(error);
            setDeactivate(false);
            setErrorOccured(true);
        });
    };

    const retry = () => {
        expiredLocal = false;
        setExpired(false);
        setUsed(false);
        close = false;
        setTimeOut(false);
        handleClickOpen();
    };

    const StyledTooltip = withStyles({
        tooltip: {
            color: "black",
            backgroundColor: "rgb(248,217,187)"
        }
    })(Tooltip);


    return (
        <React.Fragment>
            <div className="container">
                <h1 style={{ textAlign: "center", color:"#0B456E", fontFamily:"'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif"  }}>
                    Novatti Tap2Pay service is no longer available. Please contact your account manager to discuss our range of mobile SDK options.
                </h1>
                <div style={{ textAlign: "center" }}>
                    <img src="/feature-graphic1.png" alt="Feature Graphic" />
                </div>
            </div>
            {/* {(qrLoading) &&
                <div className="loading">Loading</div>}

            <div style={{ float: 'left',display: 'flex' , justifyContent: 'start' }}>
                {terminals && <div className="container-fluid" style={{padding:"20px", fontFamily:"'Montserrat', sans-serif"}}>
                    <h4 className="p-3 text-left">List Terminals</h4>
                    <table className="table table-borderless">
                        <thead >
                        <tr >
                            <th style={{fontWeight:"bold", textTransform:"none", fontSize:"small", borderBottom:"none", paddingRight:"50px"}}>Installation ID</th>
                            <th style={{fontWeight:"bold", textTransform:"none", fontSize:"small", borderBottom:"none", paddingRight:"50px"}}>Registered date   <IconButton
                                onClick={() => onSort("RegisteredDate", true)}
                                aria-label="close"
                                style={{padding:"0"}}>
                                <Icon className="fa fa-sort" style={{color: "#ACA9A9", fontSize: "20px"}} />
                            </IconButton> </th>
                            <th style={{fontWeight:"bold", textTransform:"none", fontSize:"small", borderBottom:"none", paddingRight:"50px"}}>Status  <IconButton
                                onClick={() => onSort("Status", false)}
                                aria-label="close"
                                style={{padding:"0"}}>
                                <Icon className="fa fa-sort" style={{color: "#ACA9A9", fontSize: "20px"}} />
                            </IconButton></th>
                            <th style={{fontWeight:"bold", textTransform:"none", fontSize:"small", borderBottom:"none", paddingRight:"50px", textAlign :"center"}}>Remove Device</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!terminalsLoading && terminals.map(terminal =>
                            <tr >
                                <td style={{verticalAlign:"middle",  paddingRight:"50px"}}>{terminal.InstallationID}</td>
                                <td style={{verticalAlign:"middle", paddingRight:"50px"}}>{terminal.RegisteredDate}</td>
                                <td style={{verticalAlign:"middle", paddingRight:"50px"}}>{terminal.Status}</td>
                                <td style={{display: terminal.Status === 'Deactivated'? "none" : "block", paddingRight:"50px", textAlign :"center"}}>
                                    <StyledTooltip title="Deactivate terminal"  placement="right">
                                        <IconButton
                                            onClick={() => onDeactivate(terminal.InstallationID)}
                                            aria-label="close"
                                            style={{padding:"0"}}
                                        >
                                            <Icon className="fa fa-times-circle" style={{color: "#E34B6FFF", fontSize: "20px"}} />
                                        </IconButton>
                                    </StyledTooltip>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>}

                {terminalsLoading &&
                    <div className="container">
                        <Loader ></Loader>
                    </div>
                }

                <QrDialog
                    qrLoaded={qrLoaded}
                    handleClose={handleClose}
                    expired={expired}
                    qrCode={qrCode}
                    passCode={passCode}
                    used={used}
                    open={open}
                    setExpired={setExpired}
                    onExpired={onExpired}
                    childFunc={childFunc}
                />
                <InstallationIdDialog
                    installationId={installationId}
                    handleClose={handleClose}
                    used={used}
                />
                <TimeOutDialog
                    timeOut={timeOut}
                    used={used}
                    handleClose={handleClose}
                    retry={retry}
                />
                <VerifyingDialog
                    expired = {expired}
                    close={close}
                    timeOut={timeOut}
                    used={used}
                />
                <DeactivateDialog
                    deactivate={deactivate}
                    handleClose={handleClose}
                    handleDeactivate={handleDeactivate}
                />
                <DeactivatedSuccessDialog
                    installationId={deactivateInstallationId}
                    handleClose={handleClose}
                    deactivateSuccess={deactivateSuccess}/>

                <Error
                    errorOccured={errorOccured}
                    handleClose={handleClose}/>
            </div>
            <div style={{float: 'left'}}>
                <div className="container" style={{padding:"20px"}}>
                    <button className="btn btn-secondary" style={{float: 'right', fontFamily:"'Montserrat', sans-serif"}} onClick={handleClickOpen}>+ Add
                        Terminal
                    </button>
                </div>
            </div> */}
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        authState: state.auth
    };
}

const mapDispatchToProps = {
    authenticate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
