import {AUTH_FAILED, AUTH_SUCCESS} from "./actionTypes"
import { getToken } from "api/authAPI"


export function authSuccess(data) {
    return {
        type: AUTH_SUCCESS,
        payload: data,
    };
}
export function authFail(reason) {
    return {
        type: AUTH_FAILED,
        reason,
    };
}

export function authenticate(sessionId) {
    return function (dispatch) {
        if (!sessionId) {
            dispatch(authFail("UNAUTHORIZED"));
            return;
        }
        getToken(sessionId)
            .then(response => {
                dispatch(getAuthSuccessAction(response))
            })
            .catch(err => {
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    dispatch(authFail("UNAUTHORIZED"));
                } else {
                    dispatch(authFail("SERVER_ERROR"));
                }
            })

    }
}

function getAuthSuccessAction(tokenResponse) {
    const tokenClaims = parseJwt(tokenResponse.access_token);
    const auth = {
        accessToken: tokenResponse.access_token,
        merchantId: tokenClaims.merchant_id
    }
    return authSuccess(auth);
}

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

